import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK= theme.palette.primary.dark;
 
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280 1280" >
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
		<g viewBox="0 0 980.000000 980.000000" >

 

		<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M590.000000,1281.000000 
	C393.333344,1281.000000 197.166672,1281.000000 1.000000,1281.000000 
	C1.000000,854.333313 1.000000,427.666656 1.000000,1.000000 
	C427.666656,1.000000 854.333313,1.000000 1281.000000,1.000000 
	C1281.000000,427.666656 1281.000000,854.333313 1281.000000,1281.000000 
	C1050.833374,1281.000000 820.666687,1281.000000 590.000000,1281.000000 
M433.057007,790.068237 
	C433.057007,790.068237 433.148193,790.085999 433.848877,790.080139 
	C434.527893,788.989319 435.206909,787.898438 436.042816,786.179688 
	C437.097046,784.807434 438.151276,783.435181 439.885864,781.984680 
	C440.187225,781.213257 440.488617,780.441833 440.893250,779.000000 
	C441.489471,778.049316 442.085724,777.098572 443.331055,775.986633 
	C444.113220,773.838013 444.895386,771.689392 445.786285,768.895081 
	C446.059937,768.308655 446.333588,767.722229 447.216644,766.982544 
	C447.842896,765.655762 448.469147,764.328979 449.668854,762.928345 
	C449.757751,762.276245 449.846619,761.624146 450.117004,760.391785 
	C450.519806,758.618286 451.166840,756.860474 451.281921,755.068481 
	C451.710022,748.400391 451.963318,741.721130 452.182312,734.108032 
	C452.226196,723.051758 452.270081,711.995544 452.853638,700.324158 
	C452.156525,691.904541 451.459442,683.484924 451.537231,674.967896 
	C457.889404,672.341675 459.035309,670.114746 456.870728,663.869385 
	C453.740601,664.237000 450.514099,664.615906 447.094391,664.244141 
	C443.492920,657.524475 439.999115,650.742920 436.202637,644.135315 
	C435.406616,642.749878 433.496246,642.004761 431.648010,640.585449 
	C431.437225,640.041382 431.226410,639.497253 431.094757,638.212219 
	C429.609406,637.210449 428.124054,636.208679 426.252167,635.126587 
	C426.252167,635.126587 426.058685,634.782471 426.011414,634.100647 
	C424.936371,633.440186 423.861328,632.779724 422.342041,631.994385 
	C422.342041,631.994385 422.095154,631.604553 422.036835,630.946228 
	C420.978943,630.322388 419.921082,629.698547 418.199677,628.930176 
	C416.138428,627.583984 414.077179,626.237793 411.927216,624.189514 
	C408.717682,622.922913 405.508118,621.656311 401.594635,620.467407 
	C400.752167,620.072510 399.909668,619.677673 398.805389,618.684387 
	C398.006073,618.554443 397.206726,618.424438 395.688965,618.290955 
	C394.815399,617.940308 393.941833,617.589661 392.795197,616.632568 
	C387.547089,615.684265 382.298981,614.736023 376.355225,613.849670 
	C375.924255,613.700012 375.493286,613.550293 374.811676,612.780334 
	C372.899933,612.749817 370.988159,612.719299 368.311096,612.828064 
	C367.561615,612.682983 366.812164,612.537842 365.832153,611.787781 
	C358.765503,611.198486 351.702209,610.152527 344.631653,610.100769 
	C317.529083,609.902527 290.424164,610.012512 263.320221,610.056702 
	C261.898560,610.059021 260.477631,610.514526 258.277100,610.908875 
	C257.541290,610.656311 256.805481,610.403687 255.824890,609.557800 
	C255.013016,609.055176 254.257446,608.272156 253.380020,608.095947 
	C248.085266,607.032715 243.784653,604.190002 242.683731,598.996521 
	C241.408432,592.980591 241.423737,586.679749 241.021973,580.493347 
	C240.679291,575.216492 242.937149,571.019348 246.761856,567.508057 
	C247.685425,566.660156 248.301300,565.477051 249.544830,563.913696 
	C251.381592,563.361755 253.218353,562.809875 255.471924,562.750183 
	C256.670349,562.262573 257.868805,561.774902 259.780762,561.066467 
	C260.523346,561.057861 261.265900,561.049255 262.602020,561.619995 
	C263.751862,561.719971 264.901703,561.907104 266.051575,561.907349 
	C326.742126,561.920288 387.432709,561.919189 448.123260,561.904724 
	C449.439972,561.904358 450.761719,561.765198 452.062988,561.570007 
	C452.161133,561.555237 452.113190,560.566711 452.182678,559.090454 
	C452.182678,533.646667 452.182678,508.202881 452.182678,482.791565 
	C382.802673,482.791565 313.879791,482.791565 244.542328,482.277588 
	C243.360825,482.522430 242.179306,482.767303 240.234726,483.239349 
	C238.136322,483.398407 236.037933,483.557465 233.648636,483.244720 
	C232.723022,483.545135 231.797409,483.845520 230.129776,484.458221 
	C229.066620,484.542145 228.003479,484.626068 226.666733,484.243805 
	C226.112915,484.486084 225.559113,484.728394 224.379791,485.268494 
	C223.464478,485.358002 222.549149,485.447510 220.948410,485.177399 
	C220.297180,485.460785 219.645966,485.744141 218.354904,486.295135 
	C217.219315,486.446564 216.083710,486.598022 214.738632,486.306396 
	C214.116821,486.594574 213.494995,486.882751 212.158905,487.536255 
	C211.100861,487.672485 210.042816,487.808716 208.548477,487.421295 
	C205.535248,488.060303 202.387222,488.344116 199.541321,489.424072 
	C195.570175,490.931061 191.824799,493.032928 187.624924,495.256500 
	C187.463455,495.194122 187.301987,495.131744 186.556122,494.886780 
	C186.066757,495.156097 185.577408,495.425415 184.539902,496.143494 
	C182.777603,497.155731 181.015320,498.167969 178.677277,498.956238 
	C178.438065,499.244965 178.198853,499.533722 177.807678,500.191620 
	C177.807678,500.191620 177.420959,500.290833 176.743851,500.137390 
	C176.143158,500.696289 175.542480,501.255219 174.706879,502.136139 
	C174.706879,502.136139 174.313400,502.199646 173.588165,502.060669 
	C172.034561,503.333923 170.480957,504.607178 168.972198,505.969482 
	C168.972198,505.969482 168.883224,505.924561 168.226624,505.824585 
	C167.579880,505.936279 166.933136,506.047974 165.516571,506.067871 
	C162.077744,505.243347 158.638931,504.418823 154.468613,503.418884 
	C153.891541,506.391388 153.568130,509.434814 152.664764,512.295044 
	C151.876007,514.792358 150.138565,517.106445 153.158112,519.751404 
	C152.803131,520.220215 152.448151,520.689026 151.609589,520.550476 
	C146.612488,519.789734 143.876312,521.278625 144.248230,525.012817 
	C144.628891,528.834839 144.435150,530.803040 139.996994,528.374756 
	C139.228516,527.954346 137.417191,528.654053 136.611450,529.401245 
	C136.173584,529.807312 136.703140,531.447205 137.031937,532.453613 
	C138.013733,535.458496 139.111877,538.425476 140.324814,541.596680 
	C140.324814,541.596680 140.328491,541.844666 139.625839,542.168152 
	C135.951645,545.824280 136.000977,546.870056 139.892670,549.742004 
	C137.146698,555.316101 137.261917,561.087769 138.834717,567.886047 
	C138.827316,580.935608 138.819931,593.985229 138.249420,607.615906 
	C137.370209,619.558594 140.159454,630.815735 144.967224,641.598022 
	C146.462952,644.952515 148.829758,647.918701 151.122086,651.317200 
	C151.122086,651.317200 151.171707,651.726440 150.993729,652.470764 
	C154.453354,659.446472 159.113037,665.303345 166.867096,669.125488 
	C168.562363,670.466492 170.257629,671.807495 172.033768,673.844971 
	C173.880386,674.748169 175.727005,675.651306 177.896042,676.539001 
	C177.896042,676.539001 178.103683,676.786133 178.043228,677.585876 
	C180.424347,678.877014 182.669327,680.644897 185.211807,681.370911 
	C193.251846,683.666809 201.403183,685.573120 210.340820,687.548035 
	C216.194626,688.316406 222.048416,689.084839 228.274796,690.457031 
	C231.165436,690.393005 234.056091,690.328979 237.879883,690.115784 
	C265.135620,690.126587 292.391449,690.102478 319.646942,690.188538 
	C323.436829,690.200500 327.225006,690.755737 331.213257,691.538330 
	C331.784363,691.477905 332.355499,691.417419 333.626343,691.277466 
	C334.052094,691.456055 334.477844,691.634644 335.185486,692.410950 
	C336.930725,692.866516 338.675964,693.322083 341.004944,693.785339 
	C341.310089,694.010376 341.615234,694.235474 342.026855,695.094421 
	C342.758057,695.357849 343.489288,695.621277 344.801758,696.045593 
	C345.585449,696.932129 346.369141,697.818604 347.078064,699.483276 
	C348.073639,704.710022 349.350098,709.903687 349.971802,715.174561 
	C350.430573,719.064209 350.035187,723.054504 349.251190,727.011414 
	C344.570526,728.456116 342.349030,732.863892 342.851776,739.617432 
	C342.243530,740.099182 341.635284,740.580933 340.750549,740.422974 
	C339.344910,739.542969 337.939270,738.662964 336.533630,737.783020 
	C335.957153,740.517273 330.045532,739.472778 332.005096,744.526367 
	C331.853241,744.821594 331.820923,745.129333 331.212616,745.749329 
	C330.459686,745.767883 329.706787,745.786499 328.358124,745.231506 
	C327.703369,745.144531 327.048584,744.981140 326.393860,744.981323 
	C265.479919,744.999390 204.566010,745.029358 143.652084,745.063782 
	C142.489914,745.064453 141.148331,744.780884 140.220978,745.264160 
	C139.443726,745.669128 139.150040,747.002136 138.806549,748.857788 
	C138.780106,773.906555 138.753662,798.955383 137.982407,824.032288 
	C134.412155,825.777527 135.761215,827.518677 138.206512,828.776062 
	C139.276367,829.326172 140.820663,828.994019 142.149506,828.994141 
	C205.403687,829.000854 268.657867,829.000061 331.912048,829.000000 
	C332.577881,829.000000 333.243744,828.993652 333.909546,828.998840 
	C336.796509,829.021362 338.742493,827.937012 339.464996,824.388428 
	C340.340485,824.385010 341.215973,824.381531 342.647675,824.866394 
	C345.131256,824.525085 347.614807,824.183777 350.907166,823.600647 
	C352.306366,823.543274 353.705566,823.485840 355.580902,823.888977 
	C356.733887,823.532776 357.886841,823.176636 359.673920,822.546265 
	C360.809937,822.478943 361.945953,822.411621 363.479706,822.806702 
	C364.686096,822.449341 365.892456,822.091980 367.721222,821.437500 
	C368.179596,821.430176 368.637939,821.422852 369.647217,821.869934 
	C372.105286,821.596985 374.563385,821.323975 377.342621,821.482117 
	C377.897827,821.068848 378.453003,820.655518 379.792725,820.104004 
	C383.218811,818.871277 386.644867,817.638550 390.618042,816.030029 
	C391.267242,815.928589 391.916473,815.827148 393.365570,815.874695 
	C394.634827,815.569763 396.038971,815.515991 397.151001,814.918457 
	C403.507568,811.503174 409.794647,807.958496 416.548523,803.998230 
	C417.292328,803.623474 418.036133,803.248718 419.459167,802.991150 
	C419.981873,802.358154 420.504608,801.725220 421.440552,800.562317 
	C424.378723,798.079224 427.316895,795.596130 430.913208,793.124573 
	C431.639526,792.134460 432.365845,791.144348 433.057007,790.068237 
M479.140839,751.000000 
	C474.170990,750.002869 469.896637,752.606628 467.314026,755.747925 
	C464.755615,758.859924 464.253052,763.725952 463.135529,767.899475 
	C462.934906,768.648804 463.968262,770.434265 464.701752,770.609131 
	C468.391754,771.488586 468.011597,774.209900 468.007416,776.893860 
	C467.989868,788.225342 467.965363,799.557129 468.035919,810.888123 
	C468.046997,812.664673 468.016998,814.781799 468.915894,816.138672 
	C470.588898,818.664185 469.579620,820.159607 467.783020,821.671204 
	C466.770996,822.522583 465.409973,823.043457 464.600189,824.034973 
	C462.406860,826.720825 461.025818,829.695923 463.097015,833.123779 
	C464.971008,836.225281 467.304291,834.734253 469.615234,833.739380 
	C470.348114,833.423889 471.220886,833.138062 471.981567,833.231140 
	C476.254944,833.754272 480.511047,834.895996 484.777893,834.918823 
	C513.605774,835.073181 542.434753,834.999817 571.263428,834.998535 
	C573.832275,834.998413 576.447266,835.292725 578.959534,834.912659 
	C583.073181,834.290344 587.298096,833.660400 591.094055,832.102722 
	C592.346191,831.588928 592.360779,828.178894 593.041504,826.126404 
	C594.200745,822.631042 594.535034,819.376343 591.463074,816.509949 
	C590.902405,815.986816 590.996216,814.609802 591.021423,813.632996 
	C592.039429,774.232788 588.764954,734.762817 592.934326,695.407104 
	C593.208008,692.823303 594.024414,691.962219 596.543091,691.970703 
	C615.039856,692.032776 633.537170,691.968262 652.033447,692.083740 
	C653.279480,692.091492 655.529724,693.158325 655.588379,693.873901 
	C655.865723,697.258057 659.884338,700.876465 653.788757,704.604675 
	C656.635254,705.638000 658.741821,707.161987 659.857605,706.625549 
	C661.435852,705.866638 662.282654,703.586304 663.905579,701.304565 
	C665.204956,702.080566 667.206482,703.382874 669.309998,704.491699 
	C670.557190,705.149109 671.929321,705.742920 673.307312,705.947205 
	C678.163940,706.667236 683.047241,707.206604 687.897522,707.811707 
	C687.525024,711.844299 686.568359,714.779602 687.139526,717.379150 
	C689.015137,725.915527 688.368469,734.392639 688.178162,743.014038 
	C687.659729,766.498840 688.047607,790.002686 687.960938,813.498596 
	C687.942932,818.375427 687.416626,823.249878 687.143982,828.126465 
	C686.955261,831.502075 688.579041,832.978516 691.945923,832.885437 
	C693.820435,832.833557 695.726685,833.004944 697.579590,833.311646 
	C700.313843,833.764160 703.010803,834.910522 705.727966,834.924561 
	C730.556641,835.052979 755.386230,834.999817 780.215515,835.000183 
	C784.126099,835.000244 788.248230,835.792725 791.896423,834.815063 
	C797.064941,833.429993 802.776123,836.182556 807.533142,832.377869 
	C808.730225,831.420410 810.761597,831.401184 811.758789,830.339539 
	C812.322815,829.739197 811.774658,826.879395 811.361694,826.793396 
	C809.845215,826.477539 808.175598,826.896851 807.289917,826.975220 
	C807.852295,817.137024 808.760742,807.104065 808.924866,797.058838 
	C809.199768,780.232666 809.131104,763.396973 808.892090,746.569763 
	C808.842773,743.102417 807.743042,739.533875 806.533630,736.229187 
	C805.103088,732.320435 803.559204,728.831421 803.764404,724.254211 
	C804.197632,714.588806 803.348145,704.863403 802.937439,695.166321 
	C802.890259,694.052612 802.235107,692.975464 801.956421,691.857788 
	C801.685852,690.772583 800.975037,689.269653 801.424927,688.616943 
	C804.614197,683.989807 802.532593,679.854126 800.762390,675.511047 
	C798.610718,670.231689 798.411011,670.247437 793.449585,672.801636 
	C791.901978,673.598511 789.372375,673.133240 787.549133,672.502869 
	C786.489258,672.136475 785.259949,670.288696 785.270203,669.117676 
	C785.337585,661.412720 785.661011,653.707703 786.023193,646.008484 
	C786.274231,640.673645 789.856201,638.917847 794.340515,641.808228 
	C797.653992,643.943909 799.315796,643.111145 800.044189,639.200500 
	C800.331238,637.659485 800.891296,636.016907 801.809998,634.778931 
	C803.531372,632.459290 804.358154,630.404236 802.909119,627.488892 
	C802.096313,625.853516 802.324829,623.655640 802.258972,621.705200 
	C802.177185,619.281067 802.280701,616.850769 802.295532,614.423157 
	C802.311584,611.779602 802.140320,609.120728 802.366333,606.495728 
	C802.790955,601.564453 803.890503,596.655579 803.921326,591.732422 
	C804.044922,571.989441 804.720215,552.230896 801.279114,532.602417 
	C800.494141,528.125122 801.871948,523.280579 802.155334,518.598999 
	C802.289001,516.389832 802.188721,514.166443 802.194397,511.949493 
	C800.202332,512.087585 798.095520,511.857239 796.240356,512.434937 
	C791.264526,513.984314 787.516541,511.735382 787.013123,506.569275 
	C785.976501,495.931122 785.647522,495.703339 775.257874,494.893982 
	C773.812256,494.781342 771.349060,493.378204 771.271240,492.417389 
	C770.806702,486.684113 769.542847,482.632782 762.796021,480.845703 
	C757.942322,479.560059 753.722473,477.824432 748.581055,479.474548 
	C746.273987,480.214996 743.348633,479.563416 740.859497,478.930969 
	C739.906250,478.688751 738.697754,476.685425 738.781860,475.573547 
	C739.343506,468.149323 735.633545,464.008911 728.026917,464.003418 
	C725.420044,464.001526 722.795288,463.840973 720.209778,464.083801 
	C713.279236,464.734680 706.208862,464.926636 699.505859,466.571350 
	C695.705017,467.503998 692.621704,471.994110 688.971008,466.328796 
	C688.497375,465.593811 686.130188,466.042145 684.638550,466.006927 
	C679.886780,465.894745 675.046265,466.754517 670.722656,463.555450 
	C669.587402,462.715485 667.509155,463.024139 665.863403,463.019928 
	C652.199097,462.984802 638.534546,462.999786 624.870117,463.000031 
	C577.790771,463.000854 530.711304,462.980011 483.632263,463.106445 
	C482.197418,463.110291 479.642578,464.530975 479.540894,465.494873 
	C479.211426,468.617096 479.782990,471.834351 480.006073,475.949280 
	C480.002411,567.632874 479.998749,659.316406 479.140839,751.000000 
M933.921204,729.599915 
	C930.564087,722.848877 930.638428,715.716125 930.695007,708.330383 
	C930.978333,671.379883 930.826111,634.426086 930.818298,597.473450 
	C930.818054,596.311707 930.780090,595.144226 930.663879,593.989075 
	C929.749023,584.896301 932.568237,577.366821 939.827454,571.599060 
	C941.047485,570.629700 941.978699,569.296875 943.433655,567.769958 
	C943.779968,567.727417 944.126221,567.684937 945.255676,567.835144 
	C949.872437,566.066406 954.489197,564.297668 959.813293,562.281555 
	C960.556213,562.254211 961.299194,562.226929 962.428833,562.720032 
	C963.648865,562.282410 964.868896,561.844727 966.796692,561.169678 
	C967.540283,561.159546 968.283875,561.149414 969.646362,561.683838 
	C970.634827,561.774841 971.623352,561.945435 972.611816,561.945435 
	C1027.444336,561.946838 1082.276978,561.935974 1137.109497,561.910828 
	C1138.429443,561.910217 1139.754150,561.766968 1141.058960,561.571228 
	C1141.153320,561.557129 1141.100830,560.564636 1141.155884,559.086792 
	C1141.155884,533.646301 1141.155884,508.205872 1141.155884,482.189728 
	C1138.624268,482.189728 1136.506958,482.189880 1134.389648,482.189667 
	C1068.605835,482.183533 1002.822021,482.177399 936.476135,481.579651 
	C932.955811,481.923950 929.435486,482.268280 925.284851,482.850616 
	C924.840698,482.824066 924.396606,482.797516 923.399841,482.228546 
	C920.564758,482.645599 917.729675,483.062653 914.283264,483.754028 
	C913.838562,483.755615 913.393860,483.757202 912.499939,483.263428 
	C910.964661,483.669159 909.429443,484.074860 907.275024,484.761688 
	C906.828430,484.740112 906.381775,484.718506 905.411987,484.180878 
	C900.711670,485.047485 895.944580,485.658630 891.332520,486.863220 
	C887.777771,487.791687 884.417664,489.465576 880.521362,491.227997 
	C879.916870,491.329437 879.312439,491.430847 877.933350,491.235504 
	C869.693542,495.346344 861.206421,499.044006 853.329285,503.760529 
	C849.924866,505.799011 847.780273,509.941681 844.961731,513.572266 
	C844.868835,513.899109 844.656006,514.096863 843.582458,514.107300 
	C842.761414,515.481750 841.940369,516.856262 841.031799,518.791504 
	C840.631226,519.171631 840.230591,519.551758 839.152283,520.008606 
	C836.119202,526.307434 832.938660,532.542053 830.150574,538.947571 
	C829.396851,540.679016 829.832092,542.927979 829.829224,545.614746 
	C829.723083,546.050781 829.616943,546.486755 828.835693,547.165161 
	C828.240417,550.372009 827.131104,553.578064 827.125854,556.785889 
	C827.020813,621.008972 826.928467,685.232727 827.144165,749.455261 
	C827.183472,761.161194 830.315735,772.452881 836.063782,782.741577 
	C837.991821,786.192810 841.119019,788.974121 844.081970,792.265198 
	C844.081970,792.265198 844.160278,792.692627 844.085327,793.419617 
	C846.524353,795.543640 848.963379,797.667725 851.968018,799.977844 
	C852.629395,800.689087 853.290833,801.400330 853.975403,802.804199 
	C861.132080,806.952454 868.173401,811.322632 875.505310,815.133850 
	C878.235291,816.552917 881.644958,816.664551 885.490662,817.359863 
	C891.947327,818.890442 898.403931,820.421021 904.995850,822.424194 
	C905.648926,822.376892 906.302063,822.329529 907.719116,822.198486 
	C909.483582,822.487488 911.247986,822.776550 913.165710,823.581970 
	C913.756653,823.508179 914.347595,823.434326 915.758057,823.285522 
	C918.510864,823.552429 921.263611,823.819336 924.299011,824.612000 
	C925.179382,824.524841 926.059814,824.437622 927.763000,824.214172 
	C933.247559,824.363403 938.731995,824.640442 944.216614,824.642395 
	C1008.280151,824.665344 1072.343750,824.646973 1136.407227,824.639465 
	C1137.852539,824.639343 1139.297852,824.639404 1141.346436,824.639404 
	C1141.346436,798.773010 1141.346436,773.354553 1141.800171,747.531494 
	C1139.952026,743.241882 1136.067871,745.294861 1133.310425,745.283936 
	C1085.437256,745.094238 1037.563110,745.125977 989.689148,745.174500 
	C983.804749,745.180420 977.920715,745.559265 971.218323,745.901733 
	C969.848145,745.783020 968.477966,745.664368 966.824646,744.868347 
	C965.564941,744.564941 964.286255,744.324097 963.048767,743.947571 
	C956.740173,742.028198 950.262939,740.502625 944.207214,737.971924 
	C940.537170,736.438232 937.525208,733.329651 933.856323,730.763733 
	C933.856323,730.763733 933.753357,730.380188 933.921204,729.599915 
M133.991653,909.901123 
	C133.722870,918.854126 134.197189,927.608765 139.331177,935.480896 
	C148.558594,949.629517 161.235596,955.851501 178.396133,955.838928 
	C484.549835,955.614563 790.703674,955.683044 1096.857544,955.607727 
	C1101.397217,955.606567 1106.054932,955.051758 1110.454834,953.947693 
	C1126.021729,950.041626 1135.358521,939.475220 1139.507812,924.467163 
	C1144.135864,907.727112 1138.539062,892.462036 1126.028076,881.809998 
	C1117.375000,874.442566 1107.261597,871.918701 1096.011841,871.921814 
	C790.190918,872.005432 484.370056,871.988098 178.549164,871.994019 
	C176.217010,871.994080 173.881989,872.023743 171.553131,872.137878 
	C151.736069,873.108643 137.808136,888.420349 133.602127,905.352539 
	C133.692307,906.559814 133.782471,907.767151 133.991653,909.901123 
M816.002197,812.011475 
	C816.001404,788.696106 816.045532,765.380432 815.903137,742.065918 
	C815.893188,740.438538 814.500732,738.819519 813.751221,737.196655 
	C812.835999,738.739868 811.767700,740.217590 811.068787,741.853210 
	C810.707458,742.698792 811.000244,743.824646 811.000244,744.823853 
	C810.999939,766.640381 810.867126,788.458496 811.120178,810.272095 
	C811.166748,814.281921 812.606995,818.324646 813.772949,822.242310 
	C814.120178,823.408630 815.725098,824.200684 816.761658,825.171631 
	C820.527954,820.239502 816.692688,816.744995 816.002197,812.011475 
z"/>
<path fill="#030303" opacity="1.000000" stroke="none" 
	d="
M134.149933,904.962402 
	C137.808136,888.420349 151.736069,873.108643 171.553131,872.137878 
	C173.881989,872.023743 176.217010,871.994080 178.549164,871.994019 
	C484.370056,871.988098 790.190918,872.005432 1096.011841,871.921814 
	C1107.261597,871.918701 1117.375000,874.442566 1126.028076,881.809998 
	C1138.539062,892.462036 1144.135864,907.727112 1139.507812,924.467163 
	C1135.358521,939.475220 1126.021729,950.041626 1110.454834,953.947693 
	C1106.054932,955.051758 1101.397217,955.606567 1096.857544,955.607727 
	C790.703674,955.683044 484.549835,955.614563 178.396133,955.838928 
	C161.235596,955.851501 148.558594,949.629517 139.331177,935.480896 
	C134.197189,927.608765 133.722870,918.854126 134.110229,909.195068 
	C134.202515,907.313477 134.176224,906.137939 134.149933,904.962402 
M1003.188049,931.844360 
	C1002.604614,929.333496 1001.596741,929.123901 998.998474,929.995728 
	C995.032104,931.326721 990.674927,932.300110 986.543884,932.110596 
	C982.252075,931.913879 980.426758,928.779541 981.835632,924.064514 
	C988.958191,924.064514 995.946716,923.873352 1002.916992,924.147278 
	C1006.260254,924.278625 1007.231995,922.743225 1007.653076,919.923462 
	C1008.782837,912.358826 1004.103088,906.333130 996.360291,905.986145 
	C988.398621,905.629456 982.199036,909.147766 977.761047,915.658142 
	C972.595642,923.235718 973.322571,931.491577 979.357422,935.712463 
	C985.750488,940.183899 998.806030,938.440308 1003.188049,931.844360 
M461.807007,907.035767 
	C453.703308,909.805481 448.751587,915.461365 447.289825,923.816406 
	C446.043121,930.942261 450.351532,937.261169 457.459351,937.672119 
	C462.572510,937.967651 467.876984,936.623413 472.949158,935.400940 
	C476.620422,934.516113 476.935730,931.965393 474.431641,929.167786 
	C460.042023,934.867615 453.475586,933.322021 454.505554,924.542297 
	C454.807861,924.415833 455.109344,924.181091 455.412567,924.178894 
	C462.061676,924.130066 468.718933,923.902771 475.356934,924.162476 
	C478.843597,924.298889 479.919098,922.930725 480.317017,919.726074 
	C481.570068,909.633850 473.916718,903.972046 461.807007,907.035767 
M572.011047,906.158203 
	C569.538452,907.092224 567.065796,908.026306 564.593201,908.960327 
	C564.677734,908.594421 564.762329,908.228577 564.846863,907.862671 
	C562.567139,907.377319 560.287354,906.891907 558.157715,906.438477 
	C555.617371,917.207703 553.280457,927.114441 550.958130,936.959595 
	C555.989258,938.700256 558.239807,937.179382 558.966003,932.455872 
	C559.593384,928.375244 561.158447,924.440674 561.814514,920.362000 
	C562.679993,914.981934 566.308533,912.884583 570.953308,911.960205 
	C577.289368,910.699341 578.988586,912.482178 577.557129,918.766724 
	C576.172363,924.846863 574.607300,930.886047 573.123474,936.934509 
	C577.947693,938.533997 580.467957,937.628052 581.145935,932.614868 
	C581.721313,928.360779 583.162842,924.227722 584.153320,920.025146 
	C586.651978,909.422913 583.658875,905.729492 572.011047,906.158203 
M796.185242,913.896179 
	C796.806152,911.705566 797.427002,909.515015 798.078857,907.215027 
	C793.546082,905.824097 790.752747,906.359070 789.995911,911.414124 
	C789.408936,915.335327 787.853210,919.112854 787.283875,923.034973 
	C786.558899,928.028992 783.616211,930.856201 779.118530,931.882446 
	C776.741638,932.424744 773.666687,932.376343 771.699524,931.232117 
	C770.674683,930.635986 770.932617,926.993713 771.369873,924.884705 
	C772.600952,918.946899 774.225159,913.090698 775.784607,906.840576 
	C773.170227,906.840576 771.240601,906.840576 769.027283,906.840576 
	C767.256042,913.854431 765.253357,920.704468 763.845947,927.674683 
	C762.549683,934.094666 765.883240,938.102844 772.515076,937.998596 
	C776.367676,937.938110 780.201721,936.696289 783.071533,936.165833 
	C786.082642,936.816895 788.423035,937.322937 790.601807,937.794006 
	C792.562317,929.516296 794.316040,922.111633 796.185242,913.896179 
M689.848755,912.035767 
	C690.616455,910.429932 691.384155,908.824097 692.555420,906.374268 
	C687.338318,906.374268 683.273743,906.374268 678.850464,906.374268 
	C679.690430,902.358948 680.410339,898.917480 681.130249,895.476013 
	C680.649048,895.156311 680.167786,894.836609 679.686584,894.516907 
	C677.448120,896.830566 673.956238,898.820251 673.255310,901.531433 
	C672.111328,905.956421 670.031555,907.146790 666.042297,906.623230 
	C662.014709,906.094604 660.702515,907.990601 661.404114,912.237488 
	C664.218689,912.237488 666.949524,912.237488 670.169128,912.237488 
	C668.985901,917.307678 667.909485,921.802124 666.891235,926.309753 
	C665.143311,934.046997 668.415344,938.238770 676.368774,938.077393 
	C678.964172,938.024780 681.602722,937.328979 684.114258,936.571655 
	C687.708801,935.487732 688.324768,933.296753 685.754700,930.139038 
	C684.978088,930.409424 684.239258,930.819458 683.463196,930.909729 
	C680.474731,931.257446 677.474365,931.502869 674.478394,931.786438 
	C674.455383,928.995483 673.870728,926.068726 674.506714,923.437073 
	C677.238403,912.134033 677.369202,912.165649 689.848755,912.035767 
M375.083252,912.539307 
	C376.246124,910.828369 377.408997,909.117432 378.725861,907.179932 
	C372.647949,906.364380 372.656616,906.370483 369.918732,910.244934 
	C366.942566,914.456360 363.960632,918.663940 360.955597,922.854736 
	C359.800720,924.465332 358.575134,926.025269 356.896423,928.252075 
	C355.221802,920.321106 353.798645,913.581177 352.376770,906.847168 
	C349.794647,906.847168 347.681030,906.847168 345.146301,906.847168 
	C347.285156,917.161804 349.360352,927.169617 351.723145,938.564209 
	C354.570740,937.262268 357.647491,936.825195 359.009369,935.062805 
	C364.495758,927.963623 369.452301,920.454956 375.083252,912.539307 
M877.210449,931.855774 
	C878.043335,928.463257 879.087952,925.106079 879.660095,921.670105 
	C880.551575,916.316650 884.284607,913.693665 888.800354,912.163696 
	C892.840027,910.794861 896.697144,911.490051 899.029602,916.007385 
	C901.010315,914.448120 902.620422,913.180603 904.190002,911.944946 
	C899.216614,904.714905 894.266052,904.151672 884.934204,908.329895 
	C881.145569,907.412964 878.785645,906.841797 876.559937,906.303101 
	C873.961243,917.111877 871.571289,927.052551 869.181580,936.992188 
	C873.760803,938.533752 876.643494,937.702881 877.210449,931.855774 
z"/>
<path fill="#020202" opacity="1.000000" stroke="none" 
	d="
M177.420959,500.290833 
	C177.420959,500.290833 177.807678,500.191620 178.106033,499.912903 
	C178.713181,499.531281 178.996063,499.379944 179.253021,499.180237 
	C181.015320,498.167969 182.777603,497.155731 184.864716,496.155579 
	C185.839859,495.801544 186.490189,495.435455 187.140503,495.069397 
	C187.301987,495.131744 187.463455,495.194122 188.182266,495.112061 
	C195.487991,492.626740 202.236374,490.285828 208.984772,487.944946 
	C210.042816,487.808716 211.100861,487.672485 212.780273,487.497253 
	C213.917145,487.222015 214.432632,486.985748 214.948120,486.749451 
	C216.083710,486.598022 217.219315,486.446564 218.864685,486.322723 
	C220.127579,486.079193 220.880707,485.808105 221.633820,485.536987 
	C222.549149,485.447510 223.464478,485.358002 224.864395,485.273621 
	C225.879440,485.089142 226.409882,484.899567 226.940323,484.709961 
	C228.003479,484.626068 229.066620,484.542145 230.799713,484.458527 
	C232.292953,484.211395 233.116241,483.963959 233.939529,483.716522 
	C236.037933,483.557465 238.136322,483.398407 240.873978,483.280701 
	C242.661133,483.145203 243.809036,482.968384 244.956940,482.791565 
	C313.879791,482.791565 382.802673,482.791565 452.182678,482.791565 
	C452.182678,508.202881 452.182678,533.646667 451.696106,559.695862 
	C390.429932,560.312744 329.650360,560.316467 268.870819,560.365234 
	C266.583191,560.367065 264.295929,560.805603 262.008484,561.040649 
	C261.265900,561.049255 260.523346,561.057861 259.068237,561.085815 
	C257.255524,561.489380 256.155334,561.873718 255.055115,562.257996 
	C253.218353,562.809875 251.381592,563.361755 248.971405,564.199341 
	C242.991989,567.319031 239.841461,571.859924 239.415176,577.847473 
	C239.029037,583.271057 238.983887,588.780396 239.469894,594.190796 
	C240.286697,603.283569 246.592529,609.074585 256.069672,610.151062 
	C256.805481,610.403687 257.541290,610.656311 259.022888,611.027039 
	C263.750946,611.386292 267.731903,611.810669 271.715698,611.839417 
	C303.164368,612.066528 334.613617,612.218018 366.062683,612.392761 
	C366.812164,612.537842 367.561615,612.682983 368.993896,612.986267 
	C371.471893,613.229858 373.267120,613.315186 375.062347,613.400574 
	C375.493286,613.550293 375.924255,613.700012 376.995972,614.054993 
	C382.780548,615.253235 387.924408,616.246155 393.068237,617.239014 
	C393.941833,617.589661 394.815399,617.940308 396.229584,618.538818 
	C397.535858,618.952087 398.301544,619.117432 399.067200,619.282776 
	C399.909668,619.677673 400.752167,620.072510 402.169250,620.703491 
	C405.834534,622.256897 408.925201,623.574280 412.015930,624.891602 
	C414.077179,626.237793 416.138428,627.583984 418.595276,629.314331 
	C420.025604,630.333862 421.060364,630.969177 422.095154,631.604553 
	C422.095154,631.604553 422.342041,631.994385 422.635254,632.373047 
	C423.971863,633.428589 425.015289,634.105530 426.058685,634.782471 
	C426.058685,634.782471 426.252167,635.126587 426.504639,635.503418 
	C428.176575,636.904480 429.596100,637.928833 431.015625,638.953186 
	C431.226410,639.497253 431.437225,640.041382 431.974670,641.098328 
	C440.515106,651.340881 445.974121,662.529358 448.602814,674.885559 
	C450.423981,683.445862 451.116882,692.246216 452.313965,700.939270 
	C452.270081,711.995544 452.226196,723.051758 452.009216,734.905762 
	C450.093140,746.180969 448.350189,756.658325 446.607239,767.135742 
	C446.333588,767.722229 446.059937,768.308655 445.460815,769.419556 
	C444.317566,772.011963 443.499756,774.079895 442.681946,776.147888 
	C442.085724,777.098572 441.489471,778.049316 440.534332,779.429688 
	C439.852142,780.593933 439.528870,781.328430 439.205536,782.062927 
	C438.151276,783.435181 437.097046,784.807434 435.643188,786.552002 
	C434.545105,787.978210 433.846649,789.032104 433.148193,790.085999 
	C433.148193,790.085999 433.057007,790.068237 432.765503,790.158325 
	C431.734344,791.203308 430.994690,792.158203 430.255066,793.113037 
	C427.316895,795.596130 424.378723,798.079224 420.921051,800.825806 
	C419.861023,801.684204 419.320465,802.279053 418.779938,802.873962 
	C418.036133,803.248718 417.292328,803.623474 415.983124,804.198120 
	C407.800385,808.173889 400.183044,811.949829 392.565674,815.725708 
	C391.916473,815.827148 391.267242,815.928589 389.954956,816.153687 
	C382.559998,817.990112 375.828156,819.702881 369.096313,821.415588 
	C368.637939,821.422852 368.179596,821.430176 367.080811,821.443665 
	C365.320923,821.747986 364.201447,822.046143 363.081970,822.344238 
	C361.945953,822.411621 360.809937,822.478943 359.089600,822.537720 
	C357.371765,822.828918 356.238251,823.128662 355.104767,823.428467 
	C353.705566,823.485840 352.306366,823.543274 350.095337,823.584900 
	C346.886139,823.838806 344.488800,824.108459 342.091461,824.378113 
	C341.215973,824.381531 340.340485,824.385010 338.744751,824.427429 
	C334.027496,824.522339 330.030457,824.625549 326.033386,824.626953 
	C265.202301,824.649109 204.371231,824.662903 143.540161,824.641785 
	C141.935776,824.641174 140.331528,824.226013 138.727219,824.004150 
	C138.753662,798.955383 138.780106,773.906555 139.070908,748.102417 
	C141.134247,747.167664 142.933151,746.832397 144.732208,746.831604 
	C202.208649,746.807312 259.685150,746.835327 317.161530,746.775146 
	C321.092621,746.770996 325.023102,746.142883 328.953857,745.805054 
	C329.706787,745.786499 330.459686,745.767883 331.940796,745.707642 
	C340.216949,744.337097 347.088074,741.533203 349.973755,733.881775 
	C353.664917,724.094543 352.741150,713.901245 350.625214,703.886902 
	C350.226379,701.999329 348.348297,700.424377 347.152802,698.705139 
	C346.369141,697.818604 345.585449,696.932129 344.399414,695.691406 
	C343.304840,695.044983 342.612610,694.752747 341.920380,694.460510 
	C341.615234,694.235474 341.310089,694.010376 340.533813,693.488708 
	C338.342987,692.732422 336.623322,692.272827 334.903625,691.813232 
	C334.477844,691.634644 334.052094,691.456055 333.153656,691.129272 
	C332.125305,691.007263 331.569641,691.033386 331.013977,691.059509 
	C327.225006,690.755737 323.436829,690.200500 319.646942,690.188538 
	C292.391449,690.102478 265.135620,690.126587 237.064484,690.011841 
	C233.466797,689.889648 230.684509,689.871460 227.902222,689.853271 
	C222.048416,689.084839 216.194626,688.316406 209.702332,687.294556 
	C198.743774,683.622742 188.423737,680.204468 178.103683,676.786133 
	C178.103683,676.786133 177.896042,676.539001 177.588898,676.242188 
	C175.505463,675.013123 173.729187,674.080811 171.952881,673.148499 
	C170.257629,671.807495 168.562363,670.466492 166.469666,668.657349 
	C161.105377,662.701660 156.138535,657.214050 151.171692,651.726440 
	C151.171707,651.726440 151.122086,651.317200 150.990082,650.828247 
	C148.846252,645.793030 146.163651,641.416382 144.962738,636.665161 
	C142.493057,626.894470 140.815887,616.923401 138.812546,607.034790 
	C138.819931,593.985229 138.827316,580.935608 139.004654,567.099915 
	C139.942856,560.732849 140.483505,555.109009 141.525360,549.579651 
	C143.909195,536.928467 150.258072,526.128906 158.040451,515.798828 
	C158.494965,515.364075 158.710861,515.069397 159.246277,514.754822 
	C160.627457,513.462280 161.689133,512.189514 163.003143,510.792511 
	C163.480484,510.373108 163.705475,510.077972 164.257935,509.782715 
	C166.018005,508.496552 167.450623,507.210571 168.883224,505.924561 
	C168.883224,505.924561 168.972198,505.969482 169.285980,505.888245 
	C171.170975,504.604553 172.742188,503.402100 174.313400,502.199646 
	C174.313400,502.199646 174.706879,502.136139 175.142548,502.012054 
	C176.192444,501.355591 176.806702,500.823212 177.420959,500.290833 
z"/>
<path fill="#020202" opacity="1.000000" stroke="none" 
	d="
M924.016418,824.086304 
	C921.263611,823.819336 918.510864,823.552429 915.251160,823.103210 
	C914.166992,822.969116 913.589722,823.017334 913.012451,823.065552 
	C911.247986,822.776550 909.483582,822.487488 907.137512,822.028259 
	C905.990784,821.889221 905.425720,821.920410 904.860596,821.951599 
	C898.403931,820.421021 891.947327,818.890442 884.841736,817.142822 
	C874.112610,811.987610 864.032471,807.049561 853.952271,802.111511 
	C853.290833,801.400330 852.629395,800.689087 851.581665,799.557495 
	C848.850342,796.988953 846.505310,794.840820 844.160278,792.692627 
	C844.160278,792.692627 844.081970,792.265198 843.895508,791.824158 
	C832.105347,776.658936 828.407959,759.520386 828.498047,741.261658 
	C828.817871,676.481934 829.170593,611.702393 829.510864,546.922791 
	C829.616943,546.486755 829.723083,546.050781 830.053101,545.041870 
	C833.461243,536.289917 836.645630,528.110901 839.829956,519.931885 
	C840.230591,519.551758 840.631226,519.171631 841.395142,518.409546 
	C842.613403,516.740234 843.468323,515.452881 844.323242,514.165466 
	C844.656006,514.096863 844.868835,513.899109 845.348450,513.285278 
	C847.794373,511.082336 849.607056,508.765015 851.956726,507.322052 
	C860.778870,501.904205 869.776184,496.771637 878.707947,491.532227 
	C879.312439,491.430847 879.916870,491.329437 881.132751,490.979431 
	C889.807800,488.719543 897.871521,486.708252 905.935181,484.696960 
	C906.381775,484.718506 906.828430,484.740112 907.945923,484.758423 
	C910.060913,484.423035 911.505066,484.090912 912.949158,483.758789 
	C913.393860,483.757202 913.838562,483.755615 915.007446,483.763306 
	C918.471863,483.438690 921.212158,483.104828 923.952454,482.770996 
	C924.396606,482.797516 924.840698,482.824066 926.034180,482.879761 
	C930.201782,482.663025 933.619995,482.417145 937.038208,482.171265 
	C1002.822021,482.177399 1068.605835,482.183533 1134.389648,482.189667 
	C1136.506958,482.189880 1138.624268,482.189728 1141.155884,482.189728 
	C1141.155884,508.205872 1141.155884,533.646301 1140.679565,559.709961 
	C1087.273926,560.334290 1034.344727,560.317627 981.415466,560.371399 
	C977.285828,560.375549 973.156799,560.871765 969.027466,561.139343 
	C968.283875,561.149414 967.540283,561.159546 966.095703,561.192261 
	C964.277161,561.543030 963.159607,561.871277 962.042114,562.199585 
	C961.299194,562.226929 960.556213,562.254211 959.083008,562.298889 
	C953.726013,564.091614 949.099243,565.867004 944.472534,567.642395 
	C944.126221,567.684937 943.779968,567.727417 942.891602,567.943359 
	C932.820862,573.652466 928.458740,582.146729 928.446533,592.946045 
	C928.401245,633.066956 928.348816,673.188354 928.530945,713.308533 
	C928.558350,719.338379 928.765442,725.691101 933.753357,730.380188 
	C933.753357,730.380188 933.856323,730.763733 934.041016,731.182251 
	C942.772949,741.939941 954.562195,744.634644 967.107788,745.545715 
	C968.477966,745.664368 969.848145,745.783020 971.977234,746.038940 
	C978.225159,746.414246 983.713928,746.853088 989.203247,746.859070 
	C1038.016602,746.912659 1086.830078,746.877869 1135.643555,746.933472 
	C1137.544922,746.935608 1139.445435,747.587158 1141.346436,747.936157 
	C1141.346436,773.354553 1141.346436,798.773010 1141.346436,824.639404 
	C1139.297852,824.639404 1137.852539,824.639343 1136.407227,824.639465 
	C1072.343750,824.646973 1008.280151,824.665344 944.216614,824.642395 
	C938.731995,824.640442 933.247559,824.363403 927.184326,824.067871 
	C925.742554,823.976440 924.879517,824.031372 924.016418,824.086304 
z"/>
<path fill="#FFFFFE" opacity="1.000000" stroke="none" 
	d="
M480.007446,475.015015 
	C479.782990,471.834351 479.211426,468.617096 479.540894,465.494873 
	C479.642578,464.530975 482.197418,463.110291 483.632263,463.106445 
	C530.711304,462.980011 577.790771,463.000854 624.870117,463.000031 
	C638.534546,462.999786 652.199097,462.984802 665.863403,463.019928 
	C667.509155,463.024139 669.587402,462.715485 670.722656,463.555450 
	C675.046265,466.754517 679.886780,465.894745 684.638550,466.006927 
	C686.130188,466.042145 688.497375,465.593811 688.971008,466.328796 
	C692.621704,471.994110 695.705017,467.503998 699.505859,466.571350 
	C706.208862,464.926636 713.279236,464.734680 720.209778,464.083801 
	C722.795288,463.840973 725.420044,464.001526 728.026917,464.003418 
	C735.633545,464.008911 739.343506,468.149323 738.781860,475.573547 
	C738.697754,476.685425 739.906250,478.688751 740.859497,478.930969 
	C743.348633,479.563416 746.273987,480.214996 748.581055,479.474548 
	C753.722473,477.824432 757.942322,479.560059 762.796021,480.845703 
	C769.542847,482.632782 770.806702,486.684113 771.271240,492.417389 
	C771.349060,493.378204 773.812256,494.781342 775.257874,494.893982 
	C785.647522,495.703339 785.976501,495.931122 787.013123,506.569275 
	C787.516541,511.735382 791.264526,513.984314 796.240356,512.434937 
	C798.095520,511.857239 800.202332,512.087585 802.194397,511.949493 
	C802.188721,514.166443 802.289001,516.389832 802.155334,518.598999 
	C801.871948,523.280579 800.494141,528.125122 801.279114,532.602417 
	C804.720215,552.230896 804.044922,571.989441 803.921326,591.732422 
	C803.890503,596.655579 802.790955,601.564453 802.366333,606.495728 
	C802.140320,609.120728 802.311584,611.779602 802.295532,614.423157 
	C802.280701,616.850769 802.177185,619.281067 802.258972,621.705200 
	C802.324829,623.655640 802.096313,625.853516 802.909119,627.488892 
	C804.358154,630.404236 803.531372,632.459290 801.809998,634.778931 
	C800.891296,636.016907 800.331238,637.659485 800.044189,639.200500 
	C799.315796,643.111145 797.653992,643.943909 794.340515,641.808228 
	C789.856201,638.917847 786.274231,640.673645 786.023193,646.008484 
	C785.661011,653.707703 785.337585,661.412720 785.270203,669.117676 
	C785.259949,670.288696 786.489258,672.136475 787.549133,672.502869 
	C789.372375,673.133240 791.901978,673.598511 793.449585,672.801636 
	C798.411011,670.247437 798.610718,670.231689 800.762390,675.511047 
	C802.532593,679.854126 804.614197,683.989807 801.424927,688.616943 
	C800.975037,689.269653 801.685852,690.772583 801.956421,691.857788 
	C802.235107,692.975464 802.890259,694.052612 802.937439,695.166321 
	C803.348145,704.863403 804.197632,714.588806 803.764404,724.254211 
	C803.559204,728.831421 805.103088,732.320435 806.533630,736.229187 
	C807.743042,739.533875 808.842773,743.102417 808.892090,746.569763 
	C809.131104,763.396973 809.199768,780.232666 808.924866,797.058838 
	C808.760742,807.104065 807.852295,817.137024 807.289917,826.975220 
	C808.175598,826.896851 809.845215,826.477539 811.361694,826.793396 
	C811.774658,826.879395 812.322815,829.739197 811.758789,830.339539 
	C810.761597,831.401184 808.730225,831.420410 807.533142,832.377869 
	C802.776123,836.182556 797.064941,833.429993 791.896423,834.815063 
	C788.248230,835.792725 784.126099,835.000244 780.215515,835.000183 
	C755.386230,834.999817 730.556641,835.052979 705.727966,834.924561 
	C703.010803,834.910522 700.313843,833.764160 697.579590,833.311646 
	C695.726685,833.004944 693.820435,832.833557 691.945923,832.885437 
	C688.579041,832.978516 686.955261,831.502075 687.143982,828.126465 
	C687.416626,823.249878 687.942932,818.375427 687.960938,813.498596 
	C688.047607,790.002686 687.659729,766.498840 688.178162,743.014038 
	C688.368469,734.392639 689.015137,725.915527 687.139526,717.379150 
	C686.568359,714.779602 687.525024,711.844299 687.897522,707.811707 
	C683.047241,707.206604 678.163940,706.667236 673.307312,705.947205 
	C671.929321,705.742920 670.557190,705.149109 669.309998,704.491699 
	C667.206482,703.382874 665.204956,702.080566 663.905579,701.304565 
	C662.282654,703.586304 661.435852,705.866638 659.857605,706.625549 
	C658.741821,707.161987 656.635254,705.638000 653.788757,704.604675 
	C659.884338,700.876465 655.865723,697.258057 655.588379,693.873901 
	C655.529724,693.158325 653.279480,692.091492 652.033447,692.083740 
	C633.537170,691.968262 615.039856,692.032776 596.543091,691.970703 
	C594.024414,691.962219 593.208008,692.823303 592.934326,695.407104 
	C588.764954,734.762817 592.039429,774.232788 591.021423,813.632996 
	C590.996216,814.609802 590.902405,815.986816 591.463074,816.509949 
	C594.535034,819.376343 594.200745,822.631042 593.041504,826.126404 
	C592.360779,828.178894 592.346191,831.588928 591.094055,832.102722 
	C587.298096,833.660400 583.073181,834.290344 578.959534,834.912659 
	C576.447266,835.292725 573.832275,834.998413 571.263428,834.998535 
	C542.434753,834.999817 513.605774,835.073181 484.777893,834.918823 
	C480.511047,834.895996 476.254944,833.754272 471.981567,833.231140 
	C471.220886,833.138062 470.348114,833.423889 469.615234,833.739380 
	C467.304291,834.734253 464.971008,836.225281 463.097015,833.123779 
	C461.025818,829.695923 462.406860,826.720825 464.600189,824.034973 
	C465.409973,823.043457 466.770996,822.522583 467.783020,821.671204 
	C469.579620,820.159607 470.588898,818.664185 468.915894,816.138672 
	C468.016998,814.781799 468.046997,812.664673 468.035919,810.888123 
	C467.965363,799.557129 467.989868,788.225342 468.007416,776.893860 
	C468.011597,774.209900 468.391754,771.488586 464.701752,770.609131 
	C463.968262,770.434265 462.934906,768.648804 463.135529,767.899475 
	C464.253052,763.725952 464.755615,758.859924 467.314026,755.747925 
	C469.896637,752.606628 474.170990,750.002869 479.584717,751.286987 
	C480.305725,752.047180 480.582825,752.520447 480.886658,753.328979 
	C480.812988,754.099243 480.712585,754.534302 480.277100,755.080200 
	C479.296295,757.686462 478.109436,760.177856 478.090485,762.678162 
	C477.937836,782.841248 477.994507,803.006042 478.011200,823.170288 
	C478.016235,829.224854 478.807159,829.985168 485.032898,829.987549 
	C512.362976,829.998047 539.693054,829.991943 567.023132,829.992065 
	C571.355957,829.992126 575.692688,830.108826 580.020508,829.961365 
	C584.262329,829.816895 586.256897,827.124329 585.578186,823.095337 
	C585.222473,820.983704 585.014771,818.817017 585.030151,816.677307 
	C585.324585,775.723572 585.729553,734.770447 585.889404,693.816345 
	C585.905884,689.591797 587.459351,688.903687 591.138855,688.929932 
	C610.635498,689.069275 630.139038,688.733032 649.628662,689.127502 
	C656.947144,689.275574 664.280579,690.692078 671.524963,691.989258 
	C680.799377,693.650024 686.021484,700.704102 690.375061,708.061340 
	C695.298096,716.380798 696.016907,725.876709 696.010803,735.386536 
	C695.991882,764.716370 696.071838,794.046570 695.920105,823.375671 
	C695.896912,827.863403 697.604248,829.973083 701.918884,829.976624 
	C733.581787,830.002625 765.244690,829.985840 796.907593,829.992554 
	C800.421082,829.993286 802.780273,828.525085 802.936890,824.828430 
	C803.116455,820.590454 802.993958,816.339478 802.994324,812.094116 
	C802.996033,788.763550 803.030945,765.432800 802.921204,742.102783 
	C802.913208,740.392883 801.852905,738.687988 801.178345,736.615234 
	C801.066528,735.492676 801.058105,734.735657 801.328003,733.718140 
	C801.404480,730.193481 800.910706,726.916382 801.054626,723.667480 
	C801.506958,713.453857 798.020142,704.186890 794.235229,695.050415 
	C793.266785,692.712524 790.793762,690.997986 789.005981,688.998535 
	C789.000000,689.000000 789.003662,688.987427 789.021729,688.603821 
	C788.945679,687.398438 789.169067,686.248047 788.709839,685.804260 
	C782.211548,679.525024 776.004639,672.851440 768.921753,667.301392 
	C763.899231,663.365845 757.663025,660.979370 751.098694,657.431213 
	C753.149963,656.209656 754.479553,655.394653 755.830750,654.617188 
	C761.279419,651.482300 767.132446,648.887634 772.117188,645.137695 
	C791.300720,630.706726 801.431702,611.566528 801.020508,587.228394 
	C800.888306,579.404724 800.754639,571.567505 801.054016,563.753052 
	C801.518616,551.624939 799.401184,540.046570 795.125061,528.718079 
	C791.234985,518.412354 785.221985,509.392212 777.119019,502.309631 
	C768.203918,494.517242 758.118225,488.101807 746.463562,484.718506 
	C737.899536,482.232422 729.490784,479.099487 720.810425,477.161133 
	C713.432556,475.513611 705.786560,474.742767 698.219910,474.358459 
	C691.187805,474.001312 684.103760,474.667419 676.583557,474.999298 
	C616.559998,475.084778 556.995361,475.058350 497.430786,475.014954 
	C492.612091,475.011444 487.793457,474.891754 482.799316,474.607239 
	C481.751709,474.597321 480.879578,474.806183 480.007446,475.015015 
z"/>
<path fill="#FFFFFE" opacity="1.000000" stroke="none" 
	d="
M138.354813,824.018188 
	C140.331528,824.226013 141.935776,824.641174 143.540161,824.641785 
	C204.371231,824.662903 265.202301,824.649109 326.033386,824.626953 
	C330.030457,824.625549 334.027496,824.522339 338.468628,824.611572 
	C338.742493,827.937012 336.796509,829.021362 333.909546,828.998840 
	C333.243744,828.993652 332.577881,829.000000 331.912048,829.000000 
	C268.657867,829.000061 205.403687,829.000854 142.149506,828.994141 
	C140.820663,828.994019 139.276367,829.326172 138.206512,828.776062 
	C135.761215,827.518677 134.412155,825.777527 138.354813,824.018188 
z"/>
<path fill="#FEF4E9" opacity="1.000000" stroke="none" 
	d="
M480.006775,475.482147 
	C480.879578,474.806183 481.751709,474.597321 482.507812,474.939026 
	C482.209808,478.779205 481.869690,482.068817 481.868927,485.358490 
	C481.848083,573.719604 481.862061,662.080750 481.787994,750.713989 
	C481.423859,751.655273 481.141907,752.324524 480.859955,752.993774 
	C480.582825,752.520447 480.305725,752.047180 480.011871,751.286987 
	C479.998749,659.316406 480.002411,567.632874 480.006775,475.482147 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M829.173279,547.043945 
	C829.170593,611.702393 828.817871,676.481934 828.498047,741.261658 
	C828.407959,759.520386 832.105347,776.658936 843.703552,791.722412 
	C841.119019,788.974121 837.991821,786.192810 836.063782,782.741577 
	C830.315735,772.452881 827.183472,761.161194 827.144165,749.455261 
	C826.928467,685.232727 827.020813,621.008972 827.125854,556.785889 
	C827.131104,553.578064 828.240417,550.372009 829.173279,547.043945 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M933.837280,729.990051 
	C928.765442,725.691101 928.558350,719.338379 928.530945,713.308533 
	C928.348816,673.188354 928.401245,633.066956 928.446533,592.946045 
	C928.458740,582.146729 932.820862,573.652466 942.696411,568.124390 
	C941.978699,569.296875 941.047485,570.629700 939.827454,571.599060 
	C932.568237,577.366821 929.749023,584.896301 930.663879,593.989075 
	C930.780090,595.144226 930.818054,596.311707 930.818298,597.473450 
	C930.826111,634.426086 930.978333,671.379883 930.695007,708.330383 
	C930.638428,715.716125 930.564087,722.848877 933.837280,729.990051 
z"/>
<path fill="#FFFFFE" opacity="1.000000" stroke="none" 
	d="
M816.009827,812.452271 
	C816.692688,816.744995 820.527954,820.239502 816.761658,825.171631 
	C815.725098,824.200684 814.120178,823.408630 813.772949,822.242310 
	C812.606995,818.324646 811.166748,814.281921 811.120178,810.272095 
	C810.867126,788.458496 810.999939,766.640381 811.000244,744.823853 
	C811.000244,743.824646 810.707458,742.698792 811.068787,741.853210 
	C811.767700,740.217590 812.835999,738.739868 813.751221,737.196655 
	C814.500732,738.819519 815.893188,740.438538 815.903137,742.065918 
	C816.045532,765.380432 816.001404,788.696106 816.009827,812.452271 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M328.656006,745.518311 
	C325.023102,746.142883 321.092621,746.770996 317.161530,746.775146 
	C259.685150,746.835327 202.208649,746.807312 144.732208,746.831604 
	C142.933151,746.832397 141.134247,747.167664 138.988770,747.635620 
	C139.150040,747.002136 139.443726,745.669128 140.220978,745.264160 
	C141.148331,744.780884 142.489914,745.064453 143.652084,745.063782 
	C204.566010,745.029358 265.479919,744.999390 326.393860,744.981323 
	C327.048584,744.981140 327.703369,745.144531 328.656006,745.518311 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M262.305237,561.330322 
	C264.295929,560.805603 266.583191,560.367065 268.870819,560.365234 
	C329.650360,560.316467 390.429932,560.312744 451.671326,560.166016 
	C452.113190,560.566711 452.161133,561.555237 452.062988,561.570007 
	C450.761719,561.765198 449.439972,561.904358 448.123260,561.904724 
	C387.432709,561.919189 326.742126,561.920288 266.051575,561.907349 
	C264.901703,561.907104 263.751862,561.719971 262.305237,561.330322 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M969.336914,561.411621 
	C973.156799,560.871765 977.285828,560.375549 981.415466,560.371399 
	C1034.344727,560.317627 1087.273926,560.334290 1140.659912,560.180054 
	C1141.100830,560.564636 1141.153320,561.557129 1141.058960,561.571228 
	C1139.754150,561.766968 1138.429443,561.910217 1137.109497,561.910828 
	C1082.276978,561.935974 1027.444336,561.946838 972.611816,561.945435 
	C971.623352,561.945435 970.634827,561.774841 969.336914,561.411621 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M1141.573242,747.733765 
	C1139.445435,747.587158 1137.544922,746.935608 1135.643555,746.933472 
	C1086.830078,746.877869 1038.016602,746.912659 989.203247,746.859070 
	C983.713928,746.853088 978.225159,746.414246 972.386353,745.970703 
	C977.920715,745.559265 983.804749,745.180420 989.689148,745.174500 
	C1037.563110,745.125977 1085.437256,745.094238 1133.310425,745.283936 
	C1136.067871,745.294861 1139.952026,743.241882 1141.573242,747.733765 
z"/>
<path fill="#E4E4E4" opacity="1.000000" stroke="none" 
	d="
M365.947418,612.090271 
	C334.613617,612.218018 303.164368,612.066528 271.715698,611.839417 
	C267.731903,611.810669 263.750946,611.386292 259.412506,610.952148 
	C260.477631,610.514526 261.898560,610.059021 263.320221,610.056702 
	C290.424164,610.012512 317.529083,609.902527 344.631653,610.100769 
	C351.702209,610.152527 358.765503,611.198486 365.947418,612.090271 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M452.583801,700.631714 
	C451.116882,692.246216 450.423981,683.445862 448.602814,674.885559 
	C445.974121,662.529358 440.515106,651.340881 432.200317,641.287842 
	C433.496246,642.004761 435.406616,642.749878 436.202637,644.135315 
	C439.999115,650.742920 443.492920,657.524475 447.237488,664.970703 
	C448.507812,668.819885 449.635071,671.942627 450.762329,675.065308 
	C451.459442,683.484924 452.156525,691.904541 452.583801,700.631714 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M347.115417,699.094238 
	C348.348297,700.424377 350.226379,701.999329 350.625214,703.886902 
	C352.741150,713.901245 353.664917,724.094543 349.973755,733.881775 
	C347.088074,741.533203 340.216949,744.337097 332.288574,745.557739 
	C331.820923,745.129333 331.853241,744.821594 332.425537,744.274048 
	C335.572998,743.035400 338.300018,742.049072 341.027039,741.062683 
	C341.635284,740.580933 342.243530,740.099182 343.339142,739.304504 
	C348.220734,736.197632 349.860809,731.981201 350.020355,727.000000 
	C350.035187,723.054504 350.430573,719.064209 349.971802,715.174561 
	C349.350098,709.903687 348.073639,704.710022 347.115417,699.094238 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M157.801819,515.938782 
	C150.258072,526.128906 143.909195,536.928467 141.525360,549.579651 
	C140.483505,555.109009 139.942856,560.732849 138.939651,566.632446 
	C137.261917,561.087769 137.146698,555.316101 140.015869,549.002808 
	C140.202194,546.123962 140.265335,543.984314 140.328491,541.844666 
	C140.328491,541.844666 140.324814,541.596680 140.602005,541.386353 
	C141.585846,540.334351 142.465668,539.584839 142.970810,538.635925 
	C146.058823,532.835022 149.061829,526.988892 152.093170,521.157837 
	C152.448151,520.689026 152.803131,520.220215 153.470764,519.273804 
	C155.122894,517.843689 156.462357,516.891235 157.801819,515.938782 
z"/>
<path fill="#FFFFFE" opacity="1.000000" stroke="none" 
	d="
M151.851379,520.854126 
	C149.061829,526.988892 146.058823,532.835022 142.970810,538.635925 
	C142.465668,539.584839 141.585846,540.334351 140.521881,541.291626 
	C139.111877,538.425476 138.013733,535.458496 137.031937,532.453613 
	C136.703140,531.447205 136.173584,529.807312 136.611450,529.401245 
	C137.417191,528.654053 139.228516,527.954346 139.996994,528.374756 
	C144.435150,530.803040 144.628891,528.834839 144.248230,525.012817 
	C143.876312,521.278625 146.612488,519.789734 151.851379,520.854126 
z"/>
<path fill="#E4E4E4" opacity="1.000000" stroke="none" 
	d="
M255.947281,609.854431 
	C246.592529,609.074585 240.286697,603.283569 239.469894,594.190796 
	C238.983887,588.780396 239.029037,583.271057 239.415176,577.847473 
	C239.841461,571.859924 242.991989,567.319031 248.728333,564.466553 
	C248.301300,565.477051 247.685425,566.660156 246.761856,567.508057 
	C242.937149,571.019348 240.679291,575.216492 241.021973,580.493347 
	C241.423737,586.679749 241.408432,592.980591 242.683731,598.996521 
	C243.784653,604.190002 248.085266,607.032715 253.380020,608.095947 
	C254.257446,608.272156 255.013016,609.055176 255.947281,609.854431 
z"/>
<path fill="#FFFFFE" opacity="1.000000" stroke="none" 
	d="
M158.040436,515.798828 
	C156.462357,516.891235 155.122894,517.843689 153.527710,518.975830 
	C150.138565,517.106445 151.876007,514.792358 152.664764,512.295044 
	C153.568130,509.434814 153.891541,506.391388 154.468613,503.418884 
	C158.638931,504.418823 162.077744,505.243347 165.705933,506.368958 
	C165.240356,507.707642 164.585419,508.745239 163.930481,509.782837 
	C163.705475,510.077972 163.480484,510.373108 162.654572,510.760986 
	C161.011368,512.160767 159.969055,513.467712 158.926758,514.774658 
	C158.710861,515.069397 158.494965,515.364075 158.040436,515.798828 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M138.530975,607.325317 
	C140.815887,616.923401 142.493057,626.894470 144.962738,636.665161 
	C146.163651,641.416382 148.846252,645.793030 150.827942,650.700928 
	C148.829758,647.918701 146.462952,644.952515 144.967224,641.598022 
	C140.159454,630.815735 137.370209,619.558594 138.530975,607.325317 
z"/>
<path fill="#FFFFFE" opacity="1.000000" stroke="none" 
	d="
M451.149780,675.016602 
	C449.635071,671.942627 448.507812,668.819885 447.334045,665.346008 
	C450.514099,664.615906 453.740601,664.237000 456.870728,663.869385 
	C459.035309,670.114746 457.889404,672.341675 451.149780,675.016602 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M878.320679,491.383850 
	C869.776184,496.771637 860.778870,501.904205 851.956726,507.322052 
	C849.607056,508.765015 847.794373,511.082336 845.399292,513.063354 
	C847.780273,509.941681 849.924866,505.799011 853.329285,503.760529 
	C861.206421,499.044006 869.693542,495.346344 878.320679,491.383850 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M966.966187,745.207031 
	C954.562195,744.634644 942.772949,741.939941 934.221680,731.264648 
	C937.525208,733.329651 940.537170,736.438232 944.207214,737.971924 
	C950.262939,740.502625 956.740173,742.028198 963.048767,743.947571 
	C964.286255,744.324097 965.564941,744.564941 966.966187,745.207031 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M446.911926,767.059143 
	C448.350189,756.658325 450.093140,746.180969 452.059143,735.374756 
	C451.963318,741.721130 451.710022,748.400391 451.281921,755.068481 
	C451.166840,756.860474 450.519806,758.618286 449.789856,760.855469 
	C449.340271,761.880249 449.217834,762.441223 449.095398,763.002258 
	C448.469147,764.328979 447.842896,765.655762 446.911926,767.059143 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M178.073456,677.186035 
	C188.423737,680.204468 198.743774,683.622742 209.287659,687.335571 
	C201.403183,685.573120 193.251846,683.666809 185.211807,681.370911 
	C182.669327,680.644897 180.424347,678.877014 178.073456,677.186035 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M853.963867,802.457886 
	C864.032471,807.049561 874.112610,811.987610 884.468750,817.149048 
	C881.644958,816.664551 878.235291,816.552917 875.505310,815.133850 
	C868.173401,811.322632 861.132080,806.952454 853.963867,802.457886 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M839.491089,519.970215 
	C836.645630,528.110901 833.461243,536.289917 829.999268,544.704224 
	C829.832092,542.927979 829.396851,540.679016 830.150574,538.947571 
	C832.938660,532.542053 836.119202,526.307434 839.491089,519.970215 
z"/>
<path fill="#FFFFFE" opacity="1.000000" stroke="none" 
	d="
M349.635773,727.005737 
	C349.860809,731.981201 348.220734,736.197632 343.489838,739.051270 
	C342.349030,732.863892 344.570526,728.456116 349.635773,727.005737 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M392.965637,815.800232 
	C400.183044,811.949829 407.800385,808.173889 415.761536,804.427856 
	C409.794647,807.958496 403.507568,811.503174 397.151001,814.918457 
	C396.038971,815.515991 394.634827,815.569763 392.965637,815.800232 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M151.082703,652.098633 
	C156.138535,657.214050 161.105377,662.701660 166.165100,668.516235 
	C159.113037,665.303345 154.453354,659.446472 151.082703,652.098633 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M905.673584,484.438904 
	C897.871521,486.708252 889.807800,488.719543 881.357544,490.769409 
	C884.417664,489.465576 887.777771,487.791687 891.332520,486.863220 
	C895.944580,485.658630 900.711670,485.047485 905.673584,484.438904 
z"/>
<path fill="#FFFFFE" opacity="1.000000" stroke="none" 
	d="
M340.888794,740.742798 
	C338.300018,742.049072 335.572998,743.035400 332.466492,744.041504 
	C330.045532,739.472778 335.957153,740.517273 336.533630,737.783020 
	C337.939270,738.662964 339.344910,739.542969 340.888794,740.742798 
z"/>
<path fill="#E4E4E4" opacity="1.000000" stroke="none" 
	d="
M208.766617,487.683105 
	C202.236374,490.285828 195.487991,492.626740 188.361206,494.923950 
	C191.824799,493.032928 195.570175,490.931061 199.541321,489.424072 
	C202.387222,488.344116 205.535248,488.060303 208.766617,487.683105 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M369.371765,821.642761 
	C375.828156,819.702881 382.559998,817.990112 389.681396,816.341614 
	C386.644867,817.638550 383.218811,818.871277 379.109131,820.147217 
	C377.957520,820.477234 377.489471,820.764099 377.021454,821.051025 
	C374.563385,821.323975 372.105286,821.596985 369.371765,821.642761 
z"/>
<path fill="#E4E4E4" opacity="1.000000" stroke="none" 
	d="
M392.931702,616.935791 
	C387.924408,616.246155 382.780548,615.253235 377.343811,614.024048 
	C382.298981,614.736023 387.547089,615.684265 392.931702,616.935791 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M944.864136,567.738770 
	C949.099243,565.867004 953.726013,564.091614 958.729370,562.422607 
	C954.489197,564.297668 949.872437,566.066406 944.864136,567.738770 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M936.757202,481.875458 
	C933.619995,482.417145 930.201782,482.663025 926.349365,482.760742 
	C929.435486,482.268280 932.955811,481.923950 936.757202,481.875458 
z"/>
<path fill="#FFFFFE" opacity="1.000000" stroke="none" 
	d="
M139.977158,542.006409 
	C140.265335,543.984314 140.202194,546.123962 140.043533,548.630981 
	C136.000977,546.870056 135.951645,545.824280 139.977158,542.006409 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M844.122803,793.056152 
	C846.505310,794.840820 848.850342,796.988953 851.298828,799.464478 
	C848.963379,797.667725 846.524353,795.543640 844.122803,793.056152 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M228.088501,690.155151 
	C230.684509,689.871460 233.466797,689.889648 236.597900,690.086426 
	C234.056091,690.328979 231.165436,690.393005 228.088501,690.155151 
z"/>
<path fill="#E4E4E4" opacity="1.000000" stroke="none" 
	d="
M411.971558,624.540527 
	C408.925201,623.574280 405.834534,622.256897 402.521179,620.664673 
	C405.508118,621.656311 408.717682,622.922913 411.971558,624.540527 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M923.676147,482.499756 
	C921.212158,483.104828 918.471863,483.438690 915.313110,483.626129 
	C917.729675,483.062653 920.564758,482.645599 923.676147,482.499756 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M342.369568,824.622253 
	C344.488800,824.108459 346.886139,823.838806 349.690918,823.705811 
	C347.614807,824.183777 345.131256,824.525085 342.369568,824.622253 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M443.006500,776.067261 
	C443.499756,774.079895 444.317566,772.011963 445.406464,769.742371 
	C444.895386,771.689392 444.113220,773.838013 443.006500,776.067261 
z"/>
<path fill="#E4E4E4" opacity="1.000000" stroke="none" 
	d="
M374.937012,613.090454 
	C373.267120,613.315186 371.471893,613.229858 369.376526,612.916626 
	C370.988159,612.719299 372.899933,612.749817 374.937012,613.090454 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M171.993332,673.496704 
	C173.729187,674.080811 175.505463,675.013123 177.427689,676.249939 
	C175.727005,675.651306 173.880386,674.748169 171.993332,673.496704 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M431.055176,638.582703 
	C429.596100,637.928833 428.176575,636.904480 426.697906,635.543579 
	C428.124054,636.208679 429.609406,637.210449 431.055176,638.582703 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M164.257935,509.782715 
	C164.585419,508.745239 165.240356,507.707642 166.090851,506.414856 
	C166.933136,506.047974 167.579880,505.936279 168.554932,505.874573 
	C167.450623,507.210571 166.018005,508.496552 164.257935,509.782715 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M173.950775,502.130157 
	C172.742188,503.402100 171.170975,504.604553 169.263550,505.843719 
	C170.480957,504.607178 172.034561,503.333923 173.950775,502.130157 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M912.724548,483.511108 
	C911.505066,484.090912 910.060913,484.423035 908.255493,484.617889 
	C909.429443,484.074860 910.964661,483.669159 912.724548,483.511108 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M335.044556,692.112061 
	C336.623322,692.272827 338.342987,692.732422 340.241943,693.484863 
	C338.675964,693.322083 336.930725,692.866516 335.044556,692.112061 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M962.235474,562.459778 
	C963.159607,561.871277 964.277161,561.543030 965.741821,561.310913 
	C964.868896,561.844727 963.648865,562.282410 962.235474,562.459778 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M255.263519,562.504089 
	C256.155334,561.873718 257.255524,561.489380 258.711487,561.196167 
	C257.868805,561.774902 256.670349,562.262573 255.263519,562.504089 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M843.952881,514.136353 
	C843.468323,515.452881 842.613403,516.740234 841.438843,518.129211 
	C841.940369,516.856262 842.761414,515.481750 843.952881,514.136353 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M363.280823,822.575500 
	C364.201447,822.046143 365.320923,821.747986 366.769592,821.592224 
	C365.892456,822.091980 364.686096,822.449341 363.280823,822.575500 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M244.749634,482.534576 
	C243.809036,482.968384 242.661133,483.145203 241.255524,483.167114 
	C242.179306,482.767303 243.360825,482.522430 244.749634,482.534576 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M355.342834,823.658691 
	C356.238251,823.128662 357.371765,822.828918 358.772522,822.674805 
	C357.886841,823.176636 356.733887,823.532776 355.342834,823.658691 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M133.876038,905.157471 
	C134.176224,906.137939 134.202515,907.313477 134.050720,908.731689 
	C133.782471,907.767151 133.692307,906.559814 133.876038,905.157471 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M433.498535,790.083069 
	C433.846649,789.032104 434.545105,787.978210 435.564758,786.865967 
	C435.206909,787.898438 434.527893,788.989319 433.498535,790.083069 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M430.584137,793.118774 
	C430.994690,792.158203 431.734344,791.203308 432.783081,790.201294 
	C432.365845,791.144348 431.639526,792.134460 430.584137,793.118774 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M422.065979,631.275391 
	C421.060364,630.969177 420.025604,630.333862 418.927032,629.386597 
	C419.921082,629.698547 420.978943,630.322388 422.065979,631.275391 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M426.035034,634.441528 
	C425.015289,634.105530 423.971863,633.428589 422.857361,632.435425 
	C423.861328,632.779724 424.936371,633.440186 426.035034,634.441528 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M924.157715,824.349121 
	C924.879517,824.031372 925.742554,823.976440 926.772949,824.135986 
	C926.059814,824.437622 925.179382,824.524841 924.157715,824.349121 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M233.794083,483.480621 
	C233.116241,483.963959 232.292953,484.211395 231.170731,484.302368 
	C231.797409,483.845520 232.723022,483.545135 233.794083,483.480621 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M177.082397,500.214111 
	C176.806702,500.823212 176.192444,501.355591 175.260010,501.851044 
	C175.542480,501.255219 176.143158,500.696289 177.082397,500.214111 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M331.113617,691.298950 
	C331.569641,691.033386 332.125305,691.007263 332.803772,691.169067 
	C332.355499,691.417419 331.784363,691.477905 331.113617,691.298950 
z"/>
<path fill="#E4E4E4" opacity="1.000000" stroke="none" 
	d="
M186.848312,494.978088 
	C186.490189,495.435455 185.839859,495.801544 185.138794,495.931183 
	C185.577408,495.425415 186.066757,495.156097 186.848312,494.978088 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M226.803528,484.476868 
	C226.409882,484.899567 225.879440,485.089142 225.177155,485.124695 
	C225.559113,484.728394 226.112915,484.486084 226.803528,484.476868 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M913.089111,823.323730 
	C913.589722,823.017334 914.166992,822.969116 914.841431,823.140686 
	C914.347595,823.434326 913.756653,823.508179 913.089111,823.323730 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M904.928223,822.187866 
	C905.425720,821.920410 905.990784,821.889221 906.755493,822.070068 
	C906.302063,822.329529 905.648926,822.376892 904.928223,822.187866 
z"/>
<path fill="#FFFBFB" opacity="1.000000" stroke="none" 
	d="
M377.182037,821.266602 
	C377.489471,820.764099 377.957520,820.477234 378.716858,820.216309 
	C378.453003,820.655518 377.897827,821.068848 377.182037,821.266602 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M214.843384,486.527924 
	C214.432632,486.985748 213.917145,487.222015 213.137421,487.314575 
	C213.494995,486.882751 214.116821,486.594574 214.843384,486.527924 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M419.119568,802.932556 
	C419.320465,802.279053 419.861023,801.684204 420.714447,801.090759 
	C420.504608,801.725220 419.981873,802.358154 419.119568,802.932556 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M439.545715,782.023804 
	C439.528870,781.328430 439.852142,780.593933 440.482697,779.764893 
	C440.488617,780.441833 440.187225,781.213257 439.545715,782.023804 
z"/>
<path fill="#FFFBFB" opacity="1.000000" stroke="none" 
	d="
M449.382141,762.965332 
	C449.217834,762.441223 449.340271,761.880249 449.699097,761.145630 
	C449.846619,761.624146 449.757751,762.276245 449.382141,762.965332 
z"/>
<path fill="#E4E4E4" opacity="1.000000" stroke="none" 
	d="
M398.936279,618.983582 
	C398.301544,619.117432 397.535858,618.952087 396.588806,618.540649 
	C397.206726,618.424438 398.006073,618.554443 398.936279,618.983582 
z"/>
<path fill="#CECECE" opacity="1.000000" stroke="none" 
	d="
M221.291107,485.357178 
	C220.880707,485.808105 220.127579,486.079193 219.184601,486.188904 
	C219.645966,485.744141 220.297180,485.460785 221.291107,485.357178 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M341.973633,694.777466 
	C342.612610,694.752747 343.304840,695.044983 344.108765,695.610962 
	C343.489288,695.621277 342.758057,695.357849 341.973633,694.777466 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M178.965149,499.068237 
	C178.996063,499.379944 178.713181,499.531281 178.182022,499.728333 
	C178.198853,499.533722 178.438065,499.244965 178.965149,499.068237 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M1003.289673,932.173462 
	C998.806030,938.440308 985.750488,940.183899 979.357422,935.712463 
	C973.322571,931.491577 972.595642,923.235718 977.761047,915.658142 
	C982.199036,909.147766 988.398621,905.629456 996.360291,905.986145 
	C1004.103088,906.333130 1008.782837,912.358826 1007.653076,919.923462 
	C1007.231995,922.743225 1006.260254,924.278625 1002.916992,924.147278 
	C995.946716,923.873352 988.958191,924.064514 981.835632,924.064514 
	C980.426758,928.779541 982.252075,931.913879 986.543884,932.110596 
	C990.674927,932.300110 995.032104,931.326721 998.998474,929.995728 
	C1001.596741,929.123901 1002.604614,929.333496 1003.289673,932.173462 
M1000.988708,917.656433 
	C1001.047791,913.694275 998.802795,912.038330 995.134155,911.724854 
	C989.646545,911.256226 985.777649,913.535461 983.995117,918.477722 
	C989.601074,918.477722 995.152771,918.477722 1000.988708,917.656433 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M462.194031,906.906250 
	C473.916718,903.972046 481.570068,909.633850 480.317017,919.726074 
	C479.919098,922.930725 478.843597,924.298889 475.356934,924.162476 
	C468.718933,923.902771 462.061676,924.130066 455.412567,924.178894 
	C455.109344,924.181091 454.807861,924.415833 454.506775,924.545166 
	C453.475586,933.322021 460.042023,934.867615 474.431641,929.167786 
	C476.935730,931.965393 476.620422,934.516113 472.949158,935.400940 
	C467.876984,936.623413 462.572510,937.967651 457.459351,937.672119 
	C450.351532,937.261169 446.043121,930.942261 447.289825,923.816406 
	C448.751587,915.461365 453.703308,909.805481 462.194031,906.906250 
M458.533264,914.889832 
	C457.754486,915.989990 456.975708,917.090149 456.036865,918.416504 
	C462.427551,918.416504 468.074921,918.416504 473.628265,918.416504 
	C473.765686,917.651245 473.936951,917.301758 473.864075,917.014160 
	C472.302002,910.851196 466.074280,909.747131 458.533264,914.889832 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M572.431396,906.085999 
	C583.658875,905.729492 586.651978,909.422913 584.153320,920.025146 
	C583.162842,924.227722 581.721313,928.360779 581.145935,932.614868 
	C580.467957,937.628052 577.947693,938.533997 573.123474,936.934509 
	C574.607300,930.886047 576.172363,924.846863 577.557129,918.766724 
	C578.988586,912.482178 577.289368,910.699341 570.953308,911.960205 
	C566.308533,912.884583 562.679993,914.981934 561.814514,920.362000 
	C561.158447,924.440674 559.593384,928.375244 558.966003,932.455872 
	C558.239807,937.179382 555.989258,938.700256 550.958130,936.959595 
	C553.280457,927.114441 555.617371,917.207703 558.157715,906.438477 
	C560.287354,906.891907 562.567139,907.377319 564.846863,907.862671 
	C564.762329,908.228577 564.677734,908.594421 564.593201,908.960327 
	C567.065796,908.026306 569.538452,907.092224 572.431396,906.085999 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M796.127502,914.301575 
	C794.316040,922.111633 792.562317,929.516296 790.601807,937.794006 
	C788.423035,937.322937 786.082642,936.816895 783.071533,936.165833 
	C780.201721,936.696289 776.367676,937.938110 772.515076,937.998596 
	C765.883240,938.102844 762.549683,934.094666 763.845947,927.674683 
	C765.253357,920.704468 767.256042,913.854431 769.027283,906.840576 
	C771.240601,906.840576 773.170227,906.840576 775.784607,906.840576 
	C774.225159,913.090698 772.600952,918.946899 771.369873,924.884705 
	C770.932617,926.993713 770.674683,930.635986 771.699524,931.232117 
	C773.666687,932.376343 776.741638,932.424744 779.118530,931.882446 
	C783.616211,930.856201 786.558899,928.028992 787.283875,923.034973 
	C787.853210,919.112854 789.408936,915.335327 789.995911,911.414124 
	C790.752747,906.359070 793.546082,905.824097 798.078857,907.215027 
	C797.427002,909.515015 796.806152,911.705566 796.127502,914.301575 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M689.413757,912.095886 
	C677.369202,912.165649 677.238403,912.134033 674.506714,923.437073 
	C673.870728,926.068726 674.455383,928.995483 674.478394,931.786438 
	C677.474365,931.502869 680.474731,931.257446 683.463196,930.909729 
	C684.239258,930.819458 684.978088,930.409424 685.754700,930.139038 
	C688.324768,933.296753 687.708801,935.487732 684.114258,936.571655 
	C681.602722,937.328979 678.964172,938.024780 676.368774,938.077393 
	C668.415344,938.238770 665.143311,934.046997 666.891235,926.309753 
	C667.909485,921.802124 668.985901,917.307678 670.169128,912.237488 
	C666.949524,912.237488 664.218689,912.237488 661.404114,912.237488 
	C660.702515,907.990601 662.014709,906.094604 666.042297,906.623230 
	C670.031555,907.146790 672.111328,905.956421 673.255310,901.531433 
	C673.956238,898.820251 677.448120,896.830566 679.686584,894.516907 
	C680.167786,894.836609 680.649048,895.156311 681.130249,895.476013 
	C680.410339,898.917480 679.690430,902.358948 678.850464,906.374268 
	C683.273743,906.374268 687.338318,906.374268 692.555420,906.374268 
	C691.384155,908.824097 690.616455,910.429932 689.413757,912.095886 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M374.843933,912.818420 
	C369.452301,920.454956 364.495758,927.963623 359.009369,935.062805 
	C357.647491,936.825195 354.570740,937.262268 351.723145,938.564209 
	C349.360352,927.169617 347.285156,917.161804 345.146301,906.847168 
	C347.681030,906.847168 349.794647,906.847168 352.376770,906.847168 
	C353.798645,913.581177 355.221802,920.321106 356.896423,928.252075 
	C358.575134,926.025269 359.800720,924.465332 360.955597,922.854736 
	C363.960632,918.663940 366.942566,914.456360 369.918732,910.244934 
	C372.656616,906.370483 372.647949,906.364380 378.725861,907.179932 
	C377.408997,909.117432 376.246124,910.828369 374.843933,912.818420 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M877.113953,932.244202 
	C876.643494,937.702881 873.760803,938.533752 869.181580,936.992188 
	C871.571289,927.052551 873.961243,917.111877 876.559937,906.303101 
	C878.785645,906.841797 881.145569,907.412964 884.934204,908.329895 
	C894.266052,904.151672 899.216614,904.714905 904.190002,911.944946 
	C902.620422,913.180603 901.010315,914.448120 899.029602,916.007385 
	C896.697144,911.490051 892.840027,910.794861 888.800354,912.163696 
	C884.284607,913.693665 880.551575,916.316650 879.660095,921.670105 
	C879.087952,925.106079 878.043335,928.463257 877.113953,932.244202 
z"/>
<path fill="#C7C7C7" opacity="1.000000" stroke="none" 
	d="
M159.246277,514.754822 
	C159.969055,513.467712 161.011368,512.160767 162.402222,510.885315 
	C161.689133,512.189514 160.627457,513.462280 159.246277,514.754822 
z"/>
<path fill="#FD0302" opacity="1.000000" stroke="none" 
	d="
M481.870178,750.441895 
	C481.862061,662.080750 481.848083,573.719604 481.868927,485.358490 
	C481.869690,482.068817 482.209808,478.779205 482.683289,475.157776 
	C487.793457,474.891754 492.612091,475.011444 497.430786,475.014954 
	C556.995361,475.058350 616.559998,475.084778 676.939209,475.185211 
	C679.584534,475.303650 681.416382,475.326813 683.245789,475.405121 
	C708.351807,476.480133 733.075012,479.554413 756.003357,490.755829 
	C776.659485,500.847198 791.128784,516.360657 796.349487,539.286804 
	C800.994812,559.686829 800.176758,580.381287 797.726440,600.884705 
	C796.829956,608.385864 793.461731,615.951111 789.759644,622.683472 
	C781.777161,637.200073 768.709595,646.361511 754.097534,653.494263 
	C751.845459,654.593628 749.661316,655.831970 746.475281,657.520325 
	C751.432373,659.930725 755.163513,661.699158 758.854431,663.548096 
	C770.944458,669.604370 780.704468,678.432861 789.003662,688.987427 
	C789.003662,688.987427 789.000000,689.000000 789.025513,689.359985 
	C796.402405,701.850708 799.529053,715.048401 798.731201,728.967773 
	C797.641052,727.717896 796.732971,726.727905 796.039001,725.605530 
	C774.888062,691.398682 744.752563,668.765625 706.494812,657.110229 
	C666.264648,644.853943 625.767151,644.831177 585.491943,656.945251 
	C546.270508,668.742249 515.652649,692.063904 494.396393,727.237854 
	C489.855621,734.751709 486.026917,742.695923 481.870178,750.441895 
M631.500000,623.108459 
	C639.159485,623.109314 646.851013,623.550476 654.469971,622.995178 
	C663.211121,622.358032 672.136169,621.754272 680.533142,619.480591 
	C692.413635,616.263611 697.409851,608.481750 697.578003,596.192200 
	C697.628113,592.529785 697.602417,588.865540 697.558838,585.202759 
	C697.410583,572.748352 691.641113,564.665161 679.556030,561.517761 
	C673.016846,559.814758 666.131836,558.759216 659.384338,558.659119 
	C635.745056,558.308228 612.097168,558.574707 588.453857,558.412659 
	C584.413330,558.385010 583.343750,559.887939 583.374329,563.696228 
	C583.520325,581.844543 583.565125,599.996216 583.347656,618.143127 
	C583.298279,622.259827 584.751465,623.217957 588.540344,623.172485 
	C602.525146,623.004700 616.513184,623.107483 631.500000,623.108459 
z"/>
<path fill="#FEEA3D" opacity="1.000000" stroke="none" 
	d="
M481.788025,750.713989 
	C486.026917,742.695923 489.855621,734.751709 494.396393,727.237854 
	C515.652649,692.063904 546.270508,668.742249 585.491943,656.945251 
	C625.767151,644.831177 666.264648,644.853943 706.494812,657.110229 
	C744.752563,668.765625 774.888062,691.398682 796.039001,725.605530 
	C796.732971,726.727905 797.641052,727.717896 798.858154,729.277832 
	C799.861206,731.183655 800.455444,732.581116 801.049683,733.978577 
	C801.058105,734.735657 801.066528,735.492676 801.072998,737.075317 
	C801.047180,765.888733 800.953918,793.876831 801.077942,821.864014 
	C801.095642,825.854004 800.175537,827.349426 795.860718,827.324219 
	C765.541016,827.147034 735.219116,827.133728 704.899597,827.324585 
	C700.145752,827.354492 698.719727,826.072388 698.707397,821.190857 
	C698.626770,789.228027 698.506836,757.258850 697.734680,725.307800 
	C697.469360,714.329895 693.473328,704.283936 684.954407,696.504761 
	C677.783752,689.956787 669.122314,687.304382 659.828796,687.174255 
	C636.509338,686.847717 613.183228,686.997009 589.859741,686.969055 
	C582.965576,686.960815 582.958618,686.981628 582.956726,693.908630 
	C582.944824,736.224426 582.857971,778.540588 583.029541,820.855652 
	C583.050171,825.953186 581.465332,827.379028 576.461243,827.344238 
	C546.808228,827.138062 517.153076,827.241882 487.498688,827.236877 
	C480.752594,827.235718 480.746826,827.227478 480.733429,820.410706 
	C480.690521,798.596924 480.652252,776.783203 480.612183,754.969421 
	C480.712585,754.534302 480.812988,754.099243 480.886658,753.328979 
	C481.141907,752.324524 481.423859,751.655273 481.788025,750.713989 
z"/>
<path fill="#FEF4E9" opacity="1.000000" stroke="none" 
	d="
M480.277100,755.080200 
	C480.652252,776.783203 480.690521,798.596924 480.733429,820.410706 
	C480.746826,827.227478 480.752594,827.235718 487.498688,827.236877 
	C517.153076,827.241882 546.808228,827.138062 576.461243,827.344238 
	C581.465332,827.379028 583.050171,825.953186 583.029541,820.855652 
	C582.857971,778.540588 582.944824,736.224426 582.956726,693.908630 
	C582.958618,686.981628 582.965576,686.960815 589.859741,686.969055 
	C613.183228,686.997009 636.509338,686.847717 659.828796,687.174255 
	C669.122314,687.304382 677.783752,689.956787 684.954407,696.504761 
	C693.473328,704.283936 697.469360,714.329895 697.734680,725.307800 
	C698.506836,757.258850 698.626770,789.228027 698.707397,821.190857 
	C698.719727,826.072388 700.145752,827.354492 704.899597,827.324585 
	C735.219116,827.133728 765.541016,827.147034 795.860718,827.324219 
	C800.175537,827.349426 801.095642,825.854004 801.077942,821.864014 
	C800.953918,793.876831 801.047180,765.888733 801.176392,737.440796 
	C801.852905,738.687988 802.913208,740.392883 802.921204,742.102783 
	C803.030945,765.432800 802.996033,788.763550 802.994324,812.094116 
	C802.993958,816.339478 803.116455,820.590454 802.936890,824.828430 
	C802.780273,828.525085 800.421082,829.993286 796.907593,829.992554 
	C765.244690,829.985840 733.581787,830.002625 701.918884,829.976624 
	C697.604248,829.973083 695.896912,827.863403 695.920105,823.375671 
	C696.071838,794.046570 695.991882,764.716370 696.010803,735.386536 
	C696.016907,725.876709 695.298096,716.380798 690.375061,708.061340 
	C686.021484,700.704102 680.799377,693.650024 671.524963,691.989258 
	C664.280579,690.692078 656.947144,689.275574 649.628662,689.127502 
	C630.139038,688.733032 610.635498,689.069275 591.138855,688.929932 
	C587.459351,688.903687 585.905884,689.591797 585.889404,693.816345 
	C585.729553,734.770447 585.324585,775.723572 585.030151,816.677307 
	C585.014771,818.817017 585.222473,820.983704 585.578186,823.095337 
	C586.256897,827.124329 584.262329,829.816895 580.020508,829.961365 
	C575.692688,830.108826 571.355957,829.992126 567.023132,829.992065 
	C539.693054,829.991943 512.362976,829.998047 485.032898,829.987549 
	C478.807159,829.985168 478.016235,829.224854 478.011200,823.170288 
	C477.994507,803.006042 477.937836,782.841248 478.090485,762.678162 
	C478.109436,760.177856 479.296295,757.686462 480.277100,755.080200 
z"/>
<path fill="#FEF4E9" opacity="1.000000" stroke="none" 
	d="
M789.021729,688.603821 
	C780.704468,678.432861 770.944458,669.604370 758.854431,663.548096 
	C755.163513,661.699158 751.432373,659.930725 746.475281,657.520325 
	C749.661316,655.831970 751.845459,654.593628 754.097534,653.494263 
	C768.709595,646.361511 781.777161,637.200073 789.759644,622.683472 
	C793.461731,615.951111 796.829956,608.385864 797.726440,600.884705 
	C800.176758,580.381287 800.994812,559.686829 796.349487,539.286804 
	C791.128784,516.360657 776.659485,500.847198 756.003357,490.755829 
	C733.075012,479.554413 708.351807,476.480133 683.245789,475.405121 
	C681.416382,475.326813 679.584534,475.303650 677.398193,475.068939 
	C684.103760,474.667419 691.187805,474.001312 698.219910,474.358459 
	C705.786560,474.742767 713.432556,475.513611 720.810425,477.161133 
	C729.490784,479.099487 737.899536,482.232422 746.463562,484.718506 
	C758.118225,488.101807 768.203918,494.517242 777.119019,502.309631 
	C785.221985,509.392212 791.234985,518.412354 795.125061,528.718079 
	C799.401184,540.046570 801.518616,551.624939 801.054016,563.753052 
	C800.754639,571.567505 800.888306,579.404724 801.020508,587.228394 
	C801.431702,611.566528 791.300720,630.706726 772.117188,645.137695 
	C767.132446,648.887634 761.279419,651.482300 755.830750,654.617188 
	C754.479553,655.394653 753.149963,656.209656 751.098694,657.431213 
	C757.663025,660.979370 763.899231,663.365845 768.921753,667.301392 
	C776.004639,672.851440 782.211548,679.525024 788.709839,685.804260 
	C789.169067,686.248047 788.945679,687.398438 789.021729,688.603821 
z"/>
<path fill="#FEF4E9" opacity="1.000000" stroke="none" 
	d="
M801.328064,733.718140 
	C800.455444,732.581116 799.861206,731.183655 799.140015,729.476135 
	C799.529053,715.048401 796.402405,701.850708 789.031494,689.358521 
	C790.793762,690.997986 793.266785,692.712524 794.235229,695.050415 
	C798.020142,704.186890 801.506958,713.453857 801.054626,723.667480 
	C800.910706,726.916382 801.404480,730.193481 801.328064,733.718140 
z"/>
<path fill="#030303" opacity="1.000000" stroke="none" 
	d="
M1000.846619,918.067078 
	C995.152771,918.477722 989.601074,918.477722 983.995117,918.477722 
	C985.777649,913.535461 989.646545,911.256226 995.134155,911.724854 
	C998.802795,912.038330 1001.047791,913.694275 1000.846619,918.067078 
z"/>
<path fill="#030303" opacity="1.000000" stroke="none" 
	d="
M458.821381,914.637817 
	C466.074280,909.747131 472.302002,910.851196 473.864075,917.014160 
	C473.936951,917.301758 473.765686,917.651245 473.628265,918.416504 
	C468.074921,918.416504 462.427551,918.416504 456.036865,918.416504 
	C456.975708,917.090149 457.754486,915.989990 458.821381,914.637817 
z"/>
<path fill="#FFFBFB" opacity="1.000000" stroke="none" 
	d="
M631.000000,623.108459 
	C616.513184,623.107483 602.525146,623.004700 588.540344,623.172485 
	C584.751465,623.217957 583.298279,622.259827 583.347656,618.143127 
	C583.565125,599.996216 583.520325,581.844543 583.374329,563.696228 
	C583.343750,559.887939 584.413330,558.385010 588.453857,558.412659 
	C612.097168,558.574707 635.745056,558.308228 659.384338,558.659119 
	C666.131836,558.759216 673.016846,559.814758 679.556030,561.517761 
	C691.641113,564.665161 697.410583,572.748352 697.558838,585.202759 
	C697.602417,588.865540 697.628113,592.529785 697.578003,596.192200 
	C697.409851,608.481750 692.413635,616.263611 680.533142,619.480591 
	C672.136169,621.754272 663.211121,622.358032 654.469971,622.995178 
	C646.851013,623.550476 639.159485,623.109314 631.000000,623.108459 
z"/>
	

</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
